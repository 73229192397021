import React, { useState, useEffect } from "react";
import { Data } from "../data/DataCalculator";
import "lazysizes";
import step1 from "../../public/static/images/png/loan/step_1.png";
import step2 from "../../public/static/images/png/loan/step_2.png";
import step3 from "../../public/static/images/png/loan/step_3.png";
import Modal from "./UserInterface/Modal";
import { SliderCommodity } from "./UserInterface/SliderCommodity";
import UseWindowSize from "./UseWindowSize";
import LeadsForm from "./LeadsForm";
import { formatRupiah, reformatRupiah } from "src/utils/moneyFormat";
import { GACustomEvent } from "src/utils/analytics";

const Calculator = ({ promocode, asPath }) => {
  const dataCommodity = Data.find(({ type }) => type === promocode);
  const [type, setType] = useState(promocode ? promocode : Data[0].type);
  const [isSelected, setIsSelected] = useState(
    promocode ? promocode : Data[0].type
  );
  const [dataSelected, setDataSelected] = useState(
    dataCommodity ? dataCommodity.data : Data[0].data
  );

  const MonthlyCalculation = ({ valuecredit }) => {
    return (
      <div className="monthly-calculation-container">
        <p className="title">Cicilan Bulanan Kamu</p>
        <p className="cost">{formatRupiah(valuecredit)}</p>
        <p className="bold">Termasuk biaya bulanan</p>
        <p className="italic">
          *Perhitungan bersifat simulasi dan bertujuan sebagai ilustrasi
        </p>
        <div className="cara-pengajuan-container">
          <div className="cara-pengajuan">
            <img
              data-src={step1}
              src={step1}
              alt="step-one"
              className="lazyload"
            />
            <div>
              <p>Berapa lama proses pengajuannya?</p>
              <p className="bold">Sekitar 3 menit</p>
            </div>
          </div>
          <div className="cara-pengajuan">
            <img
              data-src={step2}
              src={step2}
              alt="step-two"
              className="lazyload"
            />
            <div>
              <p>Apa saja yang diperlukan?</p>
              <p className="bold">KTP</p>
            </div>
          </div>
          <div className="cara-pengajuan">
            <img
              data-src={step3}
              src={step3}
              alt="step-three"
              className="lazyload"
            />
            <div>
              <p>Ke mana Kamu dapat mengajukan?</p>
              <p className="bold">Toko terdekat</p>
            </div>
          </div>
        </div>
        <style jsx>{`
          .monthly-calculation-container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .monthly-calculation-container p {
            color: var(--color-white);
            font-size: 14px;
            margin: 9px 0px;
          }
          .monthly-calculation-container .title {
            font-weight: bold;
            font-size: 21px;
          }
          .monthly-calculation-container .italic {
            font-style: italic;
            margin: 16px;
            width: 215px;
          }
          .monthly-calculation-container .cost {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            width: 178px;
            border-radius: 28.5px;
            background-color: #fff;
            color: var(--color-gray);
            font-size: 21px;
            font-weight: bold;
            margin: 16px;
          }
          .monthly-calculation-container .bold {
            font-weight: bold;
            font-size: 16px;
          }
          .monthly-calculation-container
            .cara-pengajuan-container
            .cara-pengajuan {
            display: flex;
            justify-content: center;
          }
          .monthly-calculation-container
            .cara-pengajuan-container
            .cara-pengajuan
            img {
            width: 16%;
            height: 16%;
            margin: 10px 32px 10px 10px;
          }
          .monthly-calculation-container
            .cara-pengajuan-container
            .cara-pengajuan
            p {
            text-align: left;
            width: 134px;
            margin: 0px;
          }
          .monthly-calculation-container
            .cara-pengajuan-container
            .cara-pengajuan
            div {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          @media only screen and (max-width: 600px) {
            .monthly-calculation-container .title {
              font-size: 15px;
              margin: 17px;
            }
            .monthly-calculation-container .cost {
              font-size: 18px;
              margin: 0px;
            }
            .monthly-calculation-container p {
              font-size: 12px;
              margin: 8px;
            }
            .monthly-calculation-container .bold {
              font-size: 14px;
            }
            .monthly-calculation-container
              .cara-pengajuan-container
              .cara-pengajuan
              div
              p {
              text-align: left;
            }
          }
        `}</style>
      </div>
    );
  };

  const CalculatorInput = ({ data }) => {
    const [price, setPrice] = useState(data.minPrice);
    const [tenor, setTenor] = useState(data.minTenor);
    const [valueMinDP, setValueMinDP] = useState(price * data.minDP);
    const maxDP = Math.ceil(price * 0.7 + data.maxAdminFee);
    const [downPayment, setDownPayment] = useState(valueMinDP);
    const [adminFee, setAdminFee] = useState(data.minAdminFee);
    const [valueInsurance, setValueInsurance] = useState(0);
    const [valueFA, setValueFA] = useState(
      price - downPayment + valueInsurance
    );
    const [valueCredit, setValueCredit] = useState(
      Math.ceil((valueFA + valueFA * data.ir * tenor) / tenor)
    );
    const [priceWarning, setPriceWarning] = useState(false);
    const [dpWarning, setDpWarning] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [proteksi, setProteksi] = useState(true);
    const [proteksiChoose, setProteksiChoose] = useState(
      "Menggunakan Proteksi Aman"
    );
    const [sliderDP, setsliderDP] = useState(downPayment + adminFee);

    const creditValueRoundUp = Math.ceil(valueCredit / 100) * 100;
    useEffect(() => {
      if (proteksi === true) {
        HandleInsurance();
      } else if (proteksi === false) {
        setValueInsurance(0);
      }
      setsliderDP(downPayment + adminFee);
      setValueFA(price - downPayment + valueInsurance);
      setValueCredit(Math.ceil((valueFA + valueFA * data.ir * tenor) / tenor));
      adjustDPMaxInputValue();
    }, [
      price,
      tenor,
      valueMinDP,
      downPayment,
      valueFA,
      valueCredit,
      sliderDP,
      valueInsurance,
    ]);
    const path = asPath ? " - " + asPath.replace(/^\/+/, "") : "";
    const value_dump = {
      proteksi: proteksiChoose,
      commodity: type + path,
      price,
      tenor,
      sliderDP,
      downPayment,
      valueFA,
      creditValueRoundUp,
    };

    const HandlePrice = (e) => {
      checkSimulationInput();
      const valuePrice = reformatRupiah(e.target.value);
      const downPaymentMin = valuePrice * data.minDP;
      const downPaymentMax = valuePrice * data.maxDP;

      if (valuePrice >= data.minPrice && valuePrice <= data.maxPrice) {
        if (type === "handphone" || type === "gadget") {
          if (valuePrice <= 2000000) {
            setDownPayment(downPaymentMin);
            setValueMinDP(downPaymentMin);
            setAdminFee(data.minAdminFee);
          } else if (valuePrice > 2000000 && valuePrice < data.specialPrice) {
            setDownPayment(downPaymentMin);
            setValueMinDP(downPaymentMin);
            setAdminFee(data.maxAdminFee);
          } else if (valuePrice >= data.specialPrice) {
            setDownPayment(downPaymentMax);
            setValueMinDP(downPaymentMax);
            setAdminFee(data.maxAdminFee);
          }
        } else if (type === "furniture") {
          if (valuePrice >= data.specialPrice) {
            setDownPayment(downPaymentMax);
            setValueMinDP(downPaymentMax);
            setAdminFee(data.maxAdminFee);
          } else {
            setDownPayment(downPaymentMin);
            setValueMinDP(downPaymentMin);
            setAdminFee(data.minAdminFee);
          }
        } else if (
          type === "kamera" ||
          type === "televisi" ||
          type === "komputer_atau_laptop"
        ) {
          setDownPayment(downPaymentMin);
          setValueMinDP(downPaymentMin);
          setAdminFee(data.minAdminFee);
        }
        setPriceWarning(false);
      } else {
        setPriceWarning(true);
        if (valuePrice > data.maxPrice) {
          setPrice(data.maxPrice);
          setPriceWarning(false);
          return;
        }
      }
      setPrice(valuePrice);
    };
    const HandleInsurance = () => {
      if (tenor === 6) {
        if (price - downPayment < 2500000) {
          setValueInsurance(22500 * tenor);
        } else if (
          price - downPayment >= 2500000 &&
          price - downPayment < 3500000
        ) {
          setValueInsurance(25000 * tenor);
        } else if (
          price - downPayment >= 3500000 &&
          price - downPayment < 4500000
        ) {
          setValueInsurance(30000 * tenor);
        } else if (
          price - downPayment >= 4500000 &&
          price - downPayment < 6000000
        ) {
          setValueInsurance(32500 * tenor);
        } else if (price - downPayment >= 6000000) {
          setValueInsurance(37500 * tenor);
        }
      } else {
        if (price - downPayment < 2500000) {
          setValueInsurance(25000 * tenor);
        } else if (
          price - downPayment >= 2500000 &&
          price - downPayment < 3500000
        ) {
          setValueInsurance(30000 * tenor);
        } else if (
          price - downPayment >= 3500000 &&
          price - downPayment < 4500000
        ) {
          setValueInsurance(32500 * tenor);
        } else if (
          price - downPayment >= 4500000 &&
          price - downPayment < 6000000
        ) {
          setValueInsurance(35000 * tenor);
        } else if (price - downPayment >= 6000000) {
          setValueInsurance(40000 * tenor);
        }
      }
    };
    const HandleTenor = (e) => {
      checkSimulationInput();
      setValueCredit(
        Math.ceil(
          (price -
            downPayment +
            (price - downPayment) * data.ir * e.target.value) /
            e.target.value
        )
      );
      setTenor(e.target.value);
    };

    const HandleDP = (e) => {
      checkSimulationInput();
      const valueDownPayment = reformatRupiah(e.target.value);
      if (valueDownPayment >= valueMinDP && valueDownPayment <= maxDP) {
        setDpWarning(false);
        setValueCredit(
          Math.ceil(
            (price - downPayment + (price - downPayment) * data.ir * tenor) /
              tenor
          )
        );
      } else {
        setDpWarning(true);
        setValueFA(0);
        setValueCredit(0);
        if (valueDownPayment > maxDP) {
          setDownPayment(maxDP);
          setDpWarning(false);
          return;
        }
      }
      setValueFA(price - downPayment + valueInsurance);
      setDownPayment(valueDownPayment);
    };

    const resetPriceInputState = () => {
      setPrice(data.minPrice);
      setPriceWarning(false);
    };

    const resetDPInputState = () => {
      setDownPayment(valueMinDP);
      setDpWarning(false);
    };

    const checkSimulationInput = () => {
      if (price < data.minPrice) {
        resetPriceInputState();
      }

      if (downPayment < valueMinDP) {
        resetDPInputState();
      }
    };

    const adjustDPMaxInputValue = () => {
      if (downPayment > maxDP) {
        setDownPayment(maxDP);
      }
    };

    const sumbitCalculator = () => {
      GACustomEvent("store_purchase_detail_find_out_more", "click");
      setPopUp(true);
    };
    const closeModal = () => {
      setPopUp(false);
    };
    const handleClickProteksi = () => {
      setProteksi(true);
      if (proteksi === false) {
        setProteksiChoose("Menggunakan Proteksi");
        setProteksi(true);
        HandleInsurance();
      } else if (proteksi === true) {
        setProteksiChoose("Tidak Menggunakan Proteksi");
        setProteksi(false);
        setValueInsurance(0);
      }
    };

    return (
      <div className="calculation-type-container">
        <div className="commodity-component-container">
          <div className="commodity-left-component">
            <div className="input-slider-wrapper">
              <div className="input-slider-container">
                {priceWarning ? (
                  <div className="input-text-warning">
                    Harga tidak boleh kurang dari {data.minPrice} dan lebih dari{" "}
                    {data.maxPrice}
                  </div>
                ) : (
                  <div className="input-text-empty-warning" />
                )}
                <div
                  className={
                    priceWarning
                      ? "input-slider-top warning"
                      : "input-slider-top"
                  }
                >
                  <div>Harga</div>
                  <div>
                    <input
                      name="price"
                      type="tel"
                      value={formatRupiah(price)}
                      onChange={HandlePrice}
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="input-slider-bot">
                  <input
                    type="range"
                    name="price"
                    min={data.minPrice}
                    max={data.maxPrice}
                    step={25000}
                    value={price}
                    onChange={HandlePrice}
                  />
                </div>
                <div className="input-range-point">
                  <div>{formatRupiah(data.minPrice)}</div>
                  <div>{formatRupiah(data.maxPrice)}</div>
                </div>
              </div>

              <div className="input-slider-container">
                <div className="input-slider-top">
                  <div>Tenor</div>
                  <div>
                    <select onChange={HandleTenor} value={tenor}>
                      {data.tenor.map((val, key) => {
                        return (
                          <option key={key} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="input-slider-bot">
                  <input
                    type="range"
                    name="tenor"
                    min={data.minTenor}
                    max={data.maxTenor}
                    step={3}
                    value={tenor}
                    onChange={HandleTenor}
                  />
                </div>
                <div className="input-range-point">
                  <div>{data.minTenor}</div>
                  <div>{data.maxTenor}</div>
                </div>
              </div>

              <div className="input-slider-container">
                {dpWarning ? (
                  <div className="input-text-warning">
                    Uang muka tidak boleh kurang dari {formatRupiah(valueMinDP)}{" "}
                    dan lebih dari {formatRupiah(maxDP)}
                  </div>
                ) : (
                  <div className="input-text-empty-warning" />
                )}
                <div
                  className={
                    dpWarning ? "input-slider-top warning" : "input-slider-top"
                  }
                >
                  <div>Uang Muka (DP)</div>
                  <div>
                    <input
                      name="downPayment"
                      type="tel"
                      value={formatRupiah(sliderDP)}
                      onChange={HandleDP}
                      maxLength={10}
                    />
                  </div>
                </div>
                <div className="input-slider-bot">
                  <input
                    type="range"
                    name="downPayment"
                    min={valueMinDP}
                    max={maxDP}
                    value={downPayment}
                    onChange={HandleDP}
                  />
                </div>
                <div className="input-range-point">
                  <div>{formatRupiah(price * data.minDP + adminFee)}</div>
                  <div>{formatRupiah(maxDP + adminFee)}</div>
                </div>
              </div>
            </div>
            <div className="commodity-protection">
              <div>
                <p className="text-proteksi">Proteksi</p>
                <div>
                  <label className="switch">
                    <input type="checkbox" checked={proteksi} readOnly />
                    <span
                      className="switch-slider round"
                      value={proteksi}
                      onClick={handleClickProteksi}
                    />
                  </label>
                </div>
              </div>
              <p className="protection-description">
                Kami memberikan kenyamanan kamu dalam berbelanja dengan proteksi
                seperti <b>AMAN</b>, <b>SANTAI</b>, dan <b>EASYCOVER</b>.
              </p>
            </div>
          </div>
          <div className="commodity-right-component">
            <MonthlyCalculation valuecredit={creditValueRoundUp} />
            <button className="ajukan-button" onClick={sumbitCalculator}>
              Ajukan Sekarang
            </button>
          </div>
        </div>
        {popUp ? (
          <Modal close={closeModal}>
            <LeadsForm
              description="Halo! Kami dengan senang hati akan membantu Anda mengajukan aplikasi kredit barang impian Anda! Isi data berikut dan kami akan segera menghubungi Anda."
              title="Formulir Pengajuan"
              valueDump={value_dump}
            ></LeadsForm>
          </Modal>
        ) : (
          ""
        )}
        <style jsx>{`
          .error-msg {
            color: var(--color-red);
            margin: 0px 0 5px 0;
          }
          input[type="range"] {
            -webkit-appearance: none;
            width: 200px;
            height: 15px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
          }
          .input-slider-wrapper {
            box-shadow: 0 3px 10px 0 var(--color-white-dark);
            border-radius: 11.87px;
            padding: 16px 16px;
            display: flex;
            margin-bottom: 16px;
            flex-direction: column;
            justify-content: space-around;
          }
          .input-slider-container {
            margin: 25px 0;
          }
          .commodity-component-container {
            display: flex;
            justify-content: space-between;
          }
          .input-slider-container .input-slider-top {
            display: flex;
            margin: 10px 0px;
            justify-content: space-between;
          }
          .input-slider-container .input-slider-top div {
            font-size: 18px;
          }
          .input-slider-container .input-slider-top div input,
          .input-slider-container .input-slider-top div select {
            font-size: 18px;
            text-shadow: 0 0 0 var(--color-gray);
            text-align: center;
            width: 152px;
            color: var(--color-green);
          }
          .input-slider-container .input-slider-top div select {
            text-align-last: center;
          }
          .input-slider-container .input-slider-bot input {
            width: 100%;
          }
          .input-slider-container .input-range-point {
            display: flex;
            justify-content: space-between;
          }
          .input-slider-container .input-range-point div {
            font-size: 11px;
            color: #a9a9a9;
          }
          .commodity-component-container .commodity-left-component {
            width: 45%;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection {
            background-color: var(--color-white);
            border-radius: 16px;
            box-shadow: 0 3px 10px 0 var(--color-white-dark);
            padding: 16px 24px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            p {
            font-size: 21px;
            font-weight: bold;
            margin: 0px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
          }
          .commodity-component-container
            .commodity-left-component
            .commodity-protection
            .protection-description {
            font-size: 18px;
            text-align: left;
            font-weight: normal;
          }
          .commodity-component-container .commodity-right-component {
            width: 50%;
            margin-left: 10px;
            background-color: #404040;
            display: flex;
            flex-direction: column;
            border-radius: 16px;
            padding: 32px;
          }
          .commodity-component-container
            .commodity-right-component
            .ajukan-button {
            height: 40px;
            width: 140px;
            font-size: 14px;
            color: var(--color-red);
            font-weight: bold;
            background-color: var(--color-white);
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            border-radius: 10px;
            margin: 30px;
          }
          .commodity-component-container
            .commodity-right-component
            .ajukan-button:hover {
            cursor: pointer;
          }
          .input-slider-container .input-text-warning {
            font-size: 12px;
            text-align: right;
            color: var(--color-red);
          }
          ::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: var(--color-red);
            cursor: pointer;
          }
          ::-moz-range-thumb {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: var(--color-red);
            cursor: pointer;
          }
          /*----------slider----------*/
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .switch input:checked + .switch-slider {
            background-color: var(--color-red);
          }
          .switch input:checked + .switch-slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .switch-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          .switch-slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: var(--color-white);
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
          /* Rounded sliders */
          .switch-slider.round {
            border-radius: 34px;
          }
          .switch-slider.round:before {
            border-radius: 50%;
          }
          @media only screen and (max-width: 600px) {
            .captcha {
              display: flex;
              justify-content: center;
            }
            .input-slider-wrapper {
              margin: 16px;
            }
            .text-proteksi {
              display: flex;
              align-items: center;
            }
            .commodity-component-container {
              flex-direction: column;
              width: 100%;
            }
            .commodity-component-container .commodity-left-component {
              width: 100%;
              margin: 0px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection {
              padding: 0px;
              margin: 16px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection
              div {
              padding: 7px 16px;
              margin: 0px;
            }
            .commodity-component-container
              .commodity-left-component
              .commodity-protection
              .protection-description {
              display: none;
            }
            .commodity-component-container .commodity-right-component {
              width: 100%;
              margin: 0px;
              margin-top: 16px;
              padding: 32px 0 32px 0;
            }
            .commodity-component-container
              .commodity-right-component
              .ajukan-button {
              font-size: 12px;
            }
            .commodity-component-container .commodity-right-component {
              border-radius: 0px;
            }
          }
        `}</style>
      </div>
    );
  };

  const CommodityItem = (props) => {
    const { image, name, type, data } = props;
    const { width } = UseWindowSize();
    const [winSize, setWinSize] = useState(null);

    useEffect(() => {
      setWinSize(width);
    }, []);

    return (
      <>
        {winSize > 600 ? (
          <div
            className="calculator-commodity-type hover"
            onClick={() => {
              setIsSelected(type);
              setDataSelected(data);
              setType(type);
            }}
          >
            <div
              className={
                type === isSelected
                  ? "calculator-commodity-type-active"
                  : "calculator-commodity-type"
              }
            >
              <img
                data-src={image}
                alt={name}
                src={image}
                className="lazyload"
              />
              <p>{name}</p>
            </div>
          </div>
        ) : (
          <div
            className="calculator-commodity-type"
            onClick={() => {
              setIsSelected(type);
              setDataSelected(data);
              setType(type);
            }}
          >
            <div
              className={
                type === isSelected
                  ? "calculator-commodity-type-active"
                  : "calculator-commodity-type"
              }
            >
              <img
                data-src={image}
                alt={name}
                src={image}
                className="lazyload"
              />
              <p>{name}</p>
            </div>
          </div>
        )}
        <style jsx>{`
          .calculator-commodity-type {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .calculator-commodity-type:hover {
            cursor: pointer;
          }
          .hover:hover p {
            font-weight: bold;
          }
          .hover:hover img {
            border-color: var(--color-red);
          }
          .calculator-commodity-type img {
            border-color: var(--color-red);
          }
          .calculator-commodity-type img {
            width: 56px;
            border: 1px solid #e9e9e9;
            border-radius: 15px;
            transition: 0.5s;
          }
          .calculator-commodity-type p {
            font-size: 12px;
          }
          .calculator-commodity-type-active {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          .calculator-commodity-type-active img {
            cursor: pointer;
            border: 1px solid var(--color-red);
          }
          .calculator-commodity-type-active p {
            font-weight: bold;
          }
          p {
            text-align: center;
          }
          @media only screen and (min-width: 600px) {
            .calculator-commodity-container {
              display: none;
            }
            .calculator-commodity-container-desktop {
              display: flex;
              align-items: flex-start;
              margin: 28px 0px;
            }
          }
          @media only screen and (max-width: 600px) {
            .calculator-commodity-type p {
              font-size: 10px;
            }
            .calculator-commodity-type img {
              width: 55px;
              border-radius: 10px;
            }

            .calculator-commodity-type-active,
            .calculator-commodity-type {
              width: calc(100vw / 4.5);
            }
          }
        `}</style>
      </>
    );
  };

  return (
    <>
      <div className="calculator-wrapper">
        <div className="calculator-container">
          <p>Simulasi Kredit</p>
          {promocode ? (
            <></>
          ) : (
            <>
              <div className="calculator-commodity-slider">
                <SliderCommodity>
                  {Data.map((obj, key) => {
                    return (
                      <CommodityItem
                        key={key}
                        image={obj.img}
                        name={obj.name}
                        type={obj.type}
                        data={obj.data}
                      />
                    );
                  })}
                </SliderCommodity>
              </div>
              <div className="calculator-commodity-container-desktop">
                {Data.map((obj, key) => {
                  return (
                    <CommodityItem
                      key={key}
                      image={obj.img}
                      name={obj.name}
                      type={obj.type}
                      data={obj.data}
                    />
                  );
                })}
              </div>
            </>
          )}

          <div className="calculator-calculation-container">
            <CalculatorInput data={dataSelected} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .calculator-wrapper {
          display: flex;
          justify-content: center;
          padding: 16px;
        }
        .calculator-container p {
          text-align: center;
        }
        .calculator-container {
          width: 100%;
          max-width: 836px;
        }
        .calculator-container p {
          text-align: center;
          font-size: 34px;
        }
        .calculator-commodity-slider {
          display: flex;
          justify-content: space-around;
        }
        .calculator-commodity-container-desktop {
          display: none;
        }
        @media only screen and (max-width: 600px) {
          .calculator-container p {
            font-size: 18px;
          }
          button {
            display: none;
          }
          .calculator-wrapper {
            padding: 0px;
          }
        }
        @media only screen and (min-width: 600px) {
          .calculator-commodity-slider {
            display: none;
          }
          .calculator-commodity-container-desktop {
            display: flex;
            align-items: self-end;
            margin: 28px 0px;
          }
        }
      `}</style>
    </>
  );
};

export default Calculator;
